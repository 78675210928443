import React from "react";
import logo from "../assets/images/kom_logo.webp";
import { Instagram } from "../assets/icons/Icons";

const Footer = ({ noMargin }: { noMargin?: boolean }) => {
  const year = new Date().getFullYear();

  return (
    <>
      <footer
        className={`bg-darkBlue text-gray-300 p-10 min-h-80 flex justify-center w-full z-40
              ${noMargin ? "" : "mt-20"}`}
      >
        <div className="container">
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className="flex flex-col justify-between">
              <a href="/">
                <img
                  src={logo}
                  className="w-12 cursor-pointer"
                  alt="Knots of Macrame"
                />
              </a>
              <p className="text-white text-sm mt-4 lg:max-w-[250px]">
                Handmade with Passion, Created with Love and Rooted in Culture
              </p>
              <p className="text-white text-sm mt-4 lg:max-w-[250px]">
                Made in India
              </p>
              <a
                className="flex flex-row items-center mt-10 lg:mt-auto"
                href="https://www.instagram.com/_knotsofmacrame_/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram bg="#fff" />
                <p className="ml-3 font-medium">
                  Follow us{" "}
                  <span className="text-white hover:opacity-70 transition-opacity duration-700">
                    _knotsofmacrame_
                  </span>
                </p>
              </a>
              <a
                href="/"
                className="mt-4 hover:opacity-70 transition-opacity duration-700"
              >
                © {year} Knots of Macrame
              </a>
            </div>
            <div className="flex flex-col md:flex-row mt-10 lg:mt-0">
              <div className="md:w-1/2">
                <p className="text-white text-sm font-medium mb-5 lg:mb-8 uppercase">
                  Quick Links
                </p>
                <div className="flex flex-col space-y-3 xl:space-y-6">
                  <a href="/blogs" className="text-grey hover:underline">
                    Blogs
                  </a>
                  <a href="/wishlist" className="text-grey hover:underline">
                    Wishlist
                  </a>
                  <a href="/orders" className="text-grey hover:underline">
                    Orders
                  </a>
                  <a href="/account" className="text-grey hover:underline">
                    My Account
                  </a>
                  <a href="/contact_us" className="text-grey hover:underline">
                    Contact Us
                  </a>
                </div>
              </div>
              <div className="mt-10 md:mt-0 md:w-1/2">
                <p className="text-white text-sm font-medium mb-8 uppercase">
                  Policies
                </p>
                <div className="flex flex-col space-y-3 xl:space-y-6">
                  <a href="/terms_of_use" className="text-grey hover:underline">
                    Terms of Use
                  </a>
                  <a
                    href="/privacy_policy"
                    className="text-grey hover:underline"
                  >
                    Privacy Policy
                  </a>
                  <a
                    href="/refund_policy"
                    className="text-grey hover:underline"
                  >
                    Refund Policy
                  </a>
                  <a
                    href="/shipping_policy"
                    className="text-grey hover:underline"
                  >
                    Shipping Policy
                  </a>
                  <a href="/faq" className="text-grey hover:underline">
                    FAQs
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </footer>
      <hr className="border-orange border-4 w-full" />
    </>
  );
};

export default Footer;
