import NavigationBar from "../components/ui/NavBar";
import Footer from "../components/Footer";

const FAQs = () => {
  return (
    <>
      <NavigationBar />
      <div className="container lg:px-24 px-2 pt-32">
        <p className="self-center whitespace-nowrap text-3xl font-semibold">
          FAQs
        </p>
        <ul className="list-disc p-5 pr-0 mt-5">
          <li className="mb-10">
            <p className="text-xl text-gray-800 font-semibold mb-2">
              What is this?
            </p>
            <p className="text-sm text-gray-600">
              Carefully hand-knotted masterpieces your walls secretly dream
              about.
            </p>
          </li>
          <li className="mb-10">
            <p className="text-xl text-gray-800 font-semibold mb-2">
              Why is this?
            </p>
            <p className="text-sm text-gray-600">
              Because bare walls are sad walls, and you don’t want your walls to
              be empty as your hearts so as to be judged by this pathetic world.
            </p>
          </li>
          <li className="mb-10">
            <p className="text-xl text-gray-800 font-semibold mb-2">
              When is this?
            </p>
            <p className="text-sm text-gray-600">
              Today. But also, forever—because timeless design doesn’t have an
              expiration date.
            </p>
          </li>
          <li className="mb-10">
            <p className="text-xl text-gray-800 font-semibold mb-2">
              How is this?
            </p>
            <p className="text-sm text-gray-600">
              Millions of man hours, countless knots, and an unhealthy amount of
              tea breaks were spent in search of perfect masterpiece for your
              wall which you might not understand.
            </p>
          </li>
          <li className="mb-10">
            <p className="text-xl text-gray-800 font-semibold mb-2">
              Who is this?
            </p>
            <p className="text-sm text-gray-600">
              We are the KOM family—Knots of Macrame. We will think to adopt
              you, if you buy one.
            </p>
          </li>
          <li>
            <p className="text-xl text-gray-800 font-semibold mb-2">
              Where is this?
            </p>
            <p className="text-sm text-gray-600">
              That's a stupid question. We'll pretend you asked a smart one.
            </p>
            <p className="text-sm text-gray-600 mt-5">
              We use premium-quality cotton cords for our macrame, but now
              you’ll probably Google the price of the cords, act like a fiber
              expert, and brag to your friends as if you personally wove every
              knot.
            </p>
          </li>
        </ul>
      </div>
      <Footer />
    </>
  );
};

export default FAQs;
