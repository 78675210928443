import React, { useCallback, useEffect, useState } from "react";
import Features from "./Features";
import Card from "./ProductCard";
import { BasketProduct } from "../lib/redux/basketSlice";
import { fetchProducts } from "../lib/apis";

const Hero = () => {
  const [swings, setSwings] = useState<BasketProduct[] | undefined>();
  const [dc, setDC] = useState<BasketProduct[] | undefined>();
  const [bags, setBags] = useState<BasketProduct[] | undefined>();

  const getItems = useCallback(async () => {
    const res = await fetchProducts("", "swing_chair");
    if (res?.status === 200) setSwings(res.data);

    const res2 = await fetchProducts("", "dream_catcher");
    if (res2?.status === 200) setDC(res2.data);

    const res3 = await fetchProducts("", "bag");
    if (res3?.status === 200) setBags(res3.data);
  }, []);

  useEffect(() => {
    getItems();
  }, [getItems]);

  return (
    <div className="flex flex-col items-center">
      <div className="w-screen h-screen bg-cover bg-center bg-no-repeat bg-gray-900 bg-hero-image mt-[5rem] md:mt-[6rem] xl:mt-[6rem] overflow-hidden">
        <div className="flex flex-col-reverse items-center h-full">
          <a
            href="/categories/living_room_decor"
            className="bg-black hover:bg-black/90 text-white text-sm font-bold px-7 py-3 uppercase mt-20 mb-40"
          >
            Shop Now
          </a>
          <div className="bg-black bg-opacity-40 max-w-md xl:max-w-lg self-center p-2 md:p-3 mx-3">
            <h1 className="text-center text-white text-xl md:text-2xl lg:text-3xl italic">
              Handmade with Passion, Created with Love and Rooted in Culture
            </h1>
          </div>
        </div>
      </div>

      <div
        id="swings"
        className="container min-h-screen w-full px-3 md:px-10 pt-[6rem] md:pt-[7rem] lg:pt-[6rem]"
      >
        <h1 className="text-2xl md:text-4xl text-center font-bold my-10 tracking-widest uppercase">
          Swing Chairs
        </h1>

        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-5 xl:gap-10">
          {swings?.slice(0, 8).map((product, index) => (
            <Card key={index} item={product} />
          ))}
        </div>

        <div className="flex justify-center">
          <a
            href="/collections/swing_chair"
            className="bg-black hover:bg-black/90 text-white text-sm font-bold px-7 py-3 uppercase my-20"
          >
            Show All
          </a>
        </div>
      </div>

      <div
        id="dream_catchers"
        className="container min-h-screen w-full px-3 md:px-10"
      >
        <h1 className="text-2xl md:text-4xl text-center font-bold my-10 tracking-widest uppercase">
          Dream Catchers
        </h1>

        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-5 xl:gap-10">
          {dc?.slice(0, 8)?.map((product, index) => (
            <Card key={index} item={product} />
          ))}
        </div>

        <div className="flex justify-center">
          <a
            href="/collections/dream_catcher"
            className="bg-black hover:bg-black/90 text-white text-sm font-bold px-7 py-3 uppercase my-20"
          >
            Show All
          </a>
        </div>
      </div>

      <div id="bags" className="container min-h-screen w-full px-3 md:px-10">
        <h1 className="text-2xl md:text-4xl text-center font-bold mb-10 tracking-widest uppercase">
          Bags
        </h1>

        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-5 xl:gap-10">
          {bags?.slice(0, 8).map((product, index) => (
            <Card key={index} item={product} />
          ))}
        </div>

        <div className="flex justify-center">
          <a
            href="/collections/bag"
            className="bg-black hover:bg-black/90 text-white text-sm font-bold px-7 py-3 uppercase my-20"
          >
            Show All
          </a>
        </div>
      </div>

      <Features />
    </div>
  );
};

export default Hero;
